.dashboard-card {
  cursor: pointer;
  min-width: 18rem;
  max-width: 18rem;
}
.my-input {
  border: 1px solid #555;
  border-radius: 1px;
  padding: 5px;
  margin-right: 10px;
}
.my-input[type="number"]{
  width: 100px;
}
