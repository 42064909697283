.my-custom-scrollbar {
    height: 400px;
    overflow-y: auto;
}
.my-custom-scrollbar thead th{
    position: sticky; top: 0;
    background-color: black;
    color: white;
}

.border-0 {
	border:0px;
}

.search-dropdown-list-scrollbar{
	max-height: 100px;
	overflow-y: auto;
}
